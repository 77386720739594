<script lang="ts">
  import { onMount } from 'svelte'
  import {
    getPendingApplications,
    contract
  } from '../helpers/eth-helper'
  import type { Company } from '../types/company';
  import CompanyList from '../components/companies/CompanyList.svelte'

  let loading: boolean = false
  let companies: Company[] = []
  const events: string[] = [
    'ApplicationReceived',
    'ApplicationCancelled',
    'Certified',
    'Deny',
    'Edit',
  ]

  onMount(async () => {
    events.forEach(event => {
      contract.on(event, async () => {
        await getCompanies();
      })
    })

    loading = true;
    await getCompanies();
    loading = false;
  })

  async function getCompanies() {
    companies = await getPendingApplications();
  }
</script>

<h2 class="margin-none">Postulants</h2>

<CompanyList {companies} {loading}
  emptyMessage="Pas de postulants actuellements ¯\_(ツ)_/¯" />
