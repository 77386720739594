<script lang="ts">
  import { onMount } from 'svelte'
  import { Form, Switch } from 'spaper'

  enum Theme {
    OS = 'OS',
    LIGHT = 'light',
    DARK = 'dark',
  }

  localStorage.theme ??= Theme.OS

  let darkTheme = localStorage.theme === Theme.DARK

  onMount(() => {
    if (localStorage.theme === Theme.OS) {
      darkTheme = window?.matchMedia('(prefers-color-scheme: dark)').matches
    }
  })

  function toggleHandler() {
    localStorage.theme = darkTheme ? Theme.DARK : Theme.LIGHT
  }

  $: document.documentElement.classList.toggle('dark', darkTheme)
</script>

<Form class="inline">
  <Switch {...$$restProps}
          class="theme-switch"
          tile={{
            on: { label: '☀️' },
            off: { label: '🌙' },
          }}
          bind:checked={darkTheme}
          on:change={toggleHandler} />
</Form>

<style lang="scss">
:global(.form-group .theme-switch) {
  height: 40px;
  margin: 20px 0 0 20px;
  width: 40px;
  float: right;
  left: 10px;
  bottom: 10px;
}
:global(.form-group .theme-switch div) {
  border: none;
}
:global(.form-group .theme-switch [class^=paper-switch-tile-card-]) {
  line-height: 40px;
}
</style>
