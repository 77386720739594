<script lang="ts">
  import { Button, Modal, CopyButton, Popover } from 'spaper'
  import { selectedAccount, connected,
           onSupportedNetwork, hasWallet,
           selectedAccountCertified,
           selectedAccountHasPendingRefund,
           selectedAccountHasOngoingTx } from '../store'
  import type { Address } from '../helpers/eth-helper'
  import { connectWallet,
           getRefund,
           quit,
           switchChain } from '../helpers/eth-helper'
  import { handleTxWithUx } from '../helpers/tx-helper'
  import wallets from '../data/wallets'
  import CertifiedIcon from './CertifiedIcon.svelte'
  import ExplorerLink from './ExplorerLink.svelte'

  const quittingNotice = `Vous quitterez la Punky Places Society,
    votre certificat sera brûlé et vous perdrez vos privilèges`
  let accountModalActive: boolean = false
  let walletModalActive: boolean = false

  async function clickHandler() {
    if (!$hasWallet) {
      walletModalActive = true
    }
    if (!$onSupportedNetwork) {
      return await switchChain();
    }
    if ($selectedAccount) {
      accountModalActive = true;
      return;
    }

    const address: Address = await connectWallet();
    selectedAccount.set(address);
  }

  async function refundHandler() {
    accountModalActive = false;
    await handleTxWithUx({
      title: 'Remboursement',
      tx: getRefund,
    });
  }

  async function quitHandler() {
    accountModalActive = false;
    await handleTxWithUx({
      title: 'Démissionner',
      tx: quit
    });
  }

  function logOut() {
    accountModalActive = false;
    selectedAccount.set(null);
  }

  $: truncatedAddress = (
    $connected ?
      $selectedAccount.substring(0, 4) + '...'
      + $selectedAccount.substring($selectedAccount.length-4)
    : ''
  )
</script>

<Button type={$onSupportedNetwork ? 'secondary' : 'danger'}
        on:click={clickHandler} isLink>
  {#if !$hasWallet}
    Installer un wallet
  {:else if !$onSupportedNetwork}
    <Popover
      class="popover-wrong-network"
      label="Veuillez sélectionner le réseau Polygon dans votre wallet"
      position="bottom"
    >
      <i class="fas fa-exclamation margin-right-small"
         aria-label="Attention"></i>
      Réseau
    </Popover>
  {:else}
    {#if $selectedAccountHasOngoingTx}
      <i class="fas fa-spinner margin-right-small"
         aria-label="Transaction en cours..."></i>
    {/if}
    {truncatedAddress || 'Connexion'}
    {#if $selectedAccountCertified}
      <CertifiedIcon class="margin-left-small" />
    {/if}
  {/if}
</Button>

<Modal class="border"
       title="Votre Compte"
       bind:active={accountModalActive}
       closeBtnText="Fermer">
  <p class="text-secondary"><code>{$selectedAccount}</code></p>
  <ExplorerLink hash={$selectedAccount} ressourceType="address" />
  <svelte:fragment slot="footer">
    {#if $selectedAccountHasPendingRefund}
      <Button on:click={refundHandler}>
        Remboursement
      </Button>
    {/if}
    {#if $selectedAccountCertified}
      <Popover label={quittingNotice} background="warning">
        <Button type="danger" on:click={quitHandler}>
          Démissionner
        </Button>
      </Popover>
    {/if}
    <CopyButton text={$selectedAccount} successMessage="Copié"
                type="secondary">
      Copier l'adresse
    </CopyButton>
    <Button on:click={logOut} type="warning">
      Déconnexion
    </Button>
  </svelte:fragment>
</Modal>

<Modal class="border"
        title="Wallet - Portefeuille"
        bind:active={walletModalActive}
        closeBtnText="Fermer">
  <p>
    Un <em>wallet</em> ou portefeuille blockchain est un logiciel qui permet aux utilisateurs
    de gérer leurs cryptomonnaies et d'interagir avec des applications décentralisées
    (comme PPS) sur la blockchain.
  </p>
  <p>Vous en aurez donc besoin pour utiliser PPS !</p>
  <p>Nous vous invitons à télécharger et installer l'un des suivants</p>
  <div class="row wallets">
    {#each Object.entries(wallets) as [name, { downloadLink, logo }]}
      <a href={downloadLink} target="_blank" rel="noreferrer">
        <figure class="margin-small">
          <img src={logo} alt="Télécharger {name}" />
          <figcaption>Télécharger {name}</figcaption>
        </figure>
      </a>
    {/each}
  </div>
</Modal>

<style lang="scss">
.fa-spinner {
  animation: spin 2.5s linear infinite,
             blink 1s alternate infinite;
}

.wallets {
  flex-wrap: nowrap;
  gap: .5rem;
  a {
    border: 1px dotted var(--secondary);
    border-radius: 0;
    display: inherit;
    flex-grow: 1;
  }
  figure {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    inline-size: 100%;
    align-items: center;
    font-size: 1rem;
  }
  img {
    inline-size: 50px;
    block-size: auto;
  }
}

:global(.paper-navbar .modal a:hover) {
  color: var(--primary-light-10);
  border-color: var(--primary-light-10);
}

:global(.popover-wrong-network::after) {
  color: inherit;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
@keyframes blink {
  to { color: var(--success); }
}
</style>
