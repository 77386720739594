<script lang="ts">
  import { onMount } from 'svelte'
  import type { BigNumber } from 'ethers'
  import { Alert, Button, Form, Modal, Input, Select } from 'spaper'
  import {
    getBanProposals,
    type Address,
    contract,
    getCertifiedCompanies,
    creatBanProposal,
  } from '../helpers/eth-helper'
  import type { Company } from '../types/company'
  import { handleTxWithUx } from '../helpers/tx-helper'
  import { selectedAccountCertified, selectedAccount } from '../store';
  import ProposalWrap from '../components/proposals/ProposalWrap.svelte'
  import ProposalAside from '../components/proposals/ProposalAside.svelte'
  import ProposalWrapSkeleton from '../components/proposals/skeletons/ProposalWrapSkeleton.svelte'
  import ProposalAsideSkeleton from '../components/proposals/skeletons/ProposalAsideSkeleton.svelte'

  interface BanProposal {
    author: Address;
    company: Address;
    companyName: string;
    reason: string;
    creationDate: BigNumber;
    expireDate: BigNumber;
    open: boolean;
    votesAgainst: BigNumber;
    votesFor: BigNumber;
    voters: Address[];
  }

  const events: string[] = [
    'VoteBan',
    'NewBanProposal',
    'Ban',
    'ClosedBanProposal',
  ]

  let banProposals: BanProposal[] = []
  let loading: boolean = true

  let proposalModalActive: boolean = false
  let bannedCompanyAddress: Address
  let companies: Company[] = []
  let banReason: string

  onMount(async () => {
    await getProposals()
    loading = false
    events.forEach((event) => {
      contract.on(event, async () => await getProposals())
    })
  })

  async function getProposals() {
    banProposals = await getBanProposals()
  }

  async function newProposalHandler() {
    companies = await getCertifiedCompanies()
    proposalModalActive = true
  }

  async function createProposal() {
    proposalModalActive = false
    handleTxWithUx({
      title: 'Nouvelle proposition de bannissement',
      tx: creatBanProposal,
      params: [bannedCompanyAddress, banReason],
      messages: { success: 'Proposition créé !' },
    })
  }

  $: bannableCompanies = companies.filter(({ sender }) => (
    sender !== $selectedAccount
  ))
</script>

<h2 class="margin-none">Propositions</h2>

{#if $selectedAccountCertified}
  <Button type="secondary"
          on:click={newProposalHandler}>
    <i class="fas fa-plus" aria-hidden="true"></i>
    Créer
  </Button>
{/if}

<Modal active={proposalModalActive}
       title="Nouvelle Proposition"
       subtitle="Bannissement"
       closeBtnText="Annuler"
       on:close={() => proposalModalActive = false}>
  <Form>
    <Select label="Entreprise" bind:value={bannedCompanyAddress}>
      <option value=""></option>
      {#each bannableCompanies as { name, sender }}
        <option value={sender}>{name}</option>
      {/each}
    </Select>
    <Input type="textarea" placeholder="Raison..."
           bind:value={banReason} />
  </Form>

  <Button slot="footer" on:click={createProposal}
          type="success"
          disabled={!bannedCompanyAddress}>
    Valider
  </Button>
</Modal>

{#if loading}
  <div class="row">
    <ProposalWrapSkeleton />
    <ProposalAsideSkeleton />
  </div>
{:else if !banProposals.length}
  <Alert class="margin-top" type="secondary">
    Pas de propositions en cours actuellement ¯\_(ツ)_/¯
  </Alert>
{:else}
  {#each banProposals as { author, company, creationDate,
                           companyName, reason, votesFor,
                           votesAgainst, voters, expireDate }}
    {@const votes = {
      for: votesFor.toNumber(),
      against: votesAgainst.toNumber(),
    }}
    <div class="row">
      <ProposalWrap {author} {creationDate} {companyName}
                    {reason} {expireDate} />
      <ProposalAside votesFor={votes.for}
                     votesAgainst={votes.against}
                     {voters} {company} />
    </div>
  {/each}
{/if}
