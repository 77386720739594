<script lang="ts">
  import { Alert } from 'spaper'
  import type { Company} from '../../types/company'
  import Loader from '../../components/Loader.svelte'
  import CompanyCard from './CompanyCard.svelte'

  export let loading: boolean = false
  export let companies: Company[] = []
  export let certified: boolean = false
  export let emptyMessage: string = 'Pas de données D:'
</script>

{#if loading}
  <Loader class="margin-top" />
{:else if !companies.length}
  <Alert type="secondary" class="margin-top">
    {emptyMessage}
  </Alert>
{:else}
  <section class="company-list">
    {#each companies as { name, logoURI, webSite, date, sender }}
      <CompanyCard {name} {logoURI} {webSite} {date} {certified}
                   address={sender} />
    {/each}
  </section>
{/if}

<style lang="scss">
$sm-card-w: 12rem;

.company-list {
  display: flex;
  flex-wrap: wrap;
  :global(& > .company-card) {
    margin: 1rem;
    max-width: 335px;
  }
  :global(.card-header) {
    text-align: center;
  }
}

@media only screen and (max-width: 992px) {
  :global(.company-card) {
    min-width: $sm-card-w;
    max-width: $sm-card-w;
  }
}
</style>
