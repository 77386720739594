<script lang="ts">
  import { link } from 'svelte-spa-router'
</script>

<h2>À Propos</h2>

<section class="margin-bottom">
  <h3 class="margin-none">Pourquoi ?</h3>
  <div class="row flex-spaces">
    <p class="align-middle">
      À Coddity, on est un peu envieux de toutes ces boîtes frimant avec leur labels, mais comme on en a pas, on a décidé de lancer le nôtre !
    </p>
    <img src="question_mark_sm_blue.png" alt="" />
  </div>
</section>

<section class="margin-bottom">
  <h3 class="margin-none">Qui sommes-nous ?</h3>
  <div class="row flex-spaces">
    <img src="software_team_m_blue.png" alt="" />
    <p>
      Punky Places Society (<abbr>PPS</abbr>) est un label d'entreprises sur la blockchain Polygon.
      Il n'y a pas de critères d'acceptation définis,
      la qualité d'une entreprise est laissée à l'estimation des entreprises déjà certifiées.
    </p>
  </div>
</section>

<section class="margin-bottom-large">
  <h3 class="margin-bottom">Comment ça marche ?</h3>
  <div class="row flex-spaces">
    <img src="gear_wheels_sm_blue.png" alt="" />
    <div class="align-middle">
      <p>
        Chaque entreprise certifiée peut participer à la validation des postulants.
      </p>
      <p>
        Toute entreprise peut postuler à la certification PPS en soumettant sa candidature.
      </p>
    </div>
  </div>
  <p>
    Une fois que votre candidature est envoyée, elle est soumise à l'examen des entreprises déjà certifiées PPS.
    Elles ont le pouvoir de voter pour ou contre l'acceptation de votre entreprise dans la communauté.
  </p>
  <p>
    Pour maintenir une qualité élevée au sein de PPS,
    chaque entreprise certifiée peut également ouvrir une demande de bannissement contre un autre membre si
    elle estime qu'elle ne répond plus aux critères de PPS.
  </p>
  <p>
    D'autres questions ? Consultez notre <a href="/faq" use:link>FAQ</a>.
  </p>
</section>

<section class="margin-bottom">
  <h3 class="margin-bottom">Prêt à nous rejoindre ?</h3>
  <div class="row flex-spaces">
    <img src="people_web_m_blue.png" alt="" />
    <p class="align-middle">
      <a href="/apply" use:link>Envoyez votre candidature</a>
      dès aujourd'hui pour faire partie de notre réseau dynamique d'entreprises.
    </p>
    <p>
      La certification PPS ne vous donne pas seulement un label blockchain unique,
      mais vous ouvre également la porte à un réseau de pairs précieux.
    </p>
  </div>
</section>

<hr/>

<div class="text-center margin-top">
  <a href="/companies" use:link>Voir les entreprises certifiées</a>
</div>
