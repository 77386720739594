const wallets = {
    'Rabby': {
        downloadLink: 'https://rabby.io/',
        logo: 'rabby.png',
    },
    'Metamask': {
        downloadLink: 'https://metamask.io/download/',
        logo: 'metamask.png',
    },
    'Brave Wallet': {
        downloadLink: 'https://brave.com/fr/wallet/',
        logo: 'brave.png',
    },
};
export default wallets;
