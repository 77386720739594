<script lang="ts">
  import type { BigNumber } from 'ethers';
  import { Article } from 'spaper'
  import { formatDate } from '../../helpers/utils'

  export let author: string
  export let creationDate: BigNumber
  export let companyName: string
  export let reason: string
  export let expireDate: BigNumber
</script>

<Article border class="border-warning border-thick col-9 col">
  <svelte:fragment slot="meta">
    Ouverte par <em>{author}</em> le <em>{formatDate(creationDate)}</em>
  </svelte:fragment>
  <svelte:fragment slot="textLead">
    <i class="fas fa-ban text-danger"></i>
    Bannir {companyName}
  </svelte:fragment>
  {reason}
  <svelte:fragment slot="footer">
    <span class="text-small italic">
      Fini le <em>{formatDate(expireDate)}</em>
    </span>
  </svelte:fragment>
</Article>
