<script lang="ts">
  import ThemeToggle from './ThemeToggle.svelte'

  const links = [
    {
      label: 'Contact',
      href: 'https://coddity.com/contact',
    },
    {
      label: 'Mentions Légales',
      href: 'https://coddity.com/mentions-legales',
    },
  ]
</script>

<footer class="row flex-edges border border-1 border-secondary
               padding padding-bottom-small background-main">
  <div class="text-secondary">
    © {(new Date).getFullYear()} Coddity - Punky Places Society
    <ThemeToggle />
  </div>

  <a href="https://coddity.com/"
           target="_blank"
           rel="noopener noreferrer">
    <img src="https://assets.coddity.com/img/coddity/coddity_inline_xs.png"
         alt="" />
  </a>

  <ul class="margin-top-none">
    {#each links as { label, href }}
      <li><a {href} target="_blank" rel="noopener noreferrer">
        {label}
      </a></li>
    {/each}
  </ul>
</footer>
