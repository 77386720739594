<script lang="ts">
  import type { PaperType } from 'spaper/types'
  import { Navbar, Button } from 'spaper'
  import ConnectButton from './ConnectButton.svelte'

  interface NavLink {
    label: string
    href: string
    type: PaperType
  }

  const links: NavLink[] = [
    {
      label: 'Devenir Punk',
      href: 'apply',
      type: 'secondary',
    },
    {
      label: 'Punky Places',
      href: 'companies',
      type: 'secondary',
    },
    {
      label: 'Postulants',
      href: 'pending',
      type: 'warning',
    },
    {
      label: 'Gouvernance',
      href: 'proposals',
      type: 'warning',
    },
    {
      label: 'FAQ',
      href: 'faq',
      type: 'success',
    },
  ]
</script>

<Navbar class="padding border border-3 border-secondary">
  <h1 slot="brand">
    <a href="/#/" class="border-secondary">Punky Places Society</a>
  </h1>
  <ul class="inline">
    {#each links as { label, href, type } }
      <li>
        <Button {type}
                href="/#/{href}">
          {label}
        </Button>
      </li>
    {/each}
    <li>
      <ConnectButton />
    </li>
  </ul>
</Navbar>

<style lang="scss">
:global(nav .modal) {
  /* FIX: papercss nav div default margin */
  margin: 0;
}
:global(nav .modal a) {
  background-image: none;
}

@media only screen and (max-width: 1400px) {
  nav ul.inline li {
    margin: 1rem .5rem;
  }
}

@media only screen and (max-width: 769px) {
  nav ul.inline li {
    margin: 2rem .5rem;
  }
}

h1 {
  font-size: 2rem;
  a {
    border-color: var(--secondary);
    &:hover {
      color: var(--primary-light-10);
      border-color: var(--secondary-light-10);
    }
  }
}

@media only screen and (max-width: 992px) {
  ul {
    margin-top: 2rem;
    li:not(:last-of-type) {
      margin: 2rem 0;
    }
  }
}
</style>
