<script lang="ts">
  import { onMount } from 'svelte'
  import { push } from 'svelte-spa-router'
  import partial from 'lodash.partial'
  import { Input, Button, Form, Badge, Popover, Alert } from 'spaper'
  import { handleTxWithUx } from '../helpers/tx-helper'
  import { apply, getApplicationPrice } from '../helpers/eth-helper'
  import { onSupportedNetwork, selectedAccountCertified } from '../store'

  const feeInfo = "Cela nous permet d'éviter le spam et de couvrir nos frais de gestion et developpement"

  let applicationPrice: number = 0
  let companyName: string
  let webSite: string
  let logoURI: string
  let canSubmit: boolean = false

  onMount(async () => {
    applicationPrice = await getApplicationPrice()
  })

  async function submit(e: Event) {
    // Can't use modifier on Button
    e.preventDefault();

    await handleTxWithUx({
      title: 'Postuler',
      tx: apply,
      params: [companyName, webSite, logoURI, applicationPrice],
      onEnd: partial(push, '/pending'),
    })
  }

  $: canSubmit = !$selectedAccountCertified
    && $onSupportedNetwork
    && !!(companyName && webSite && logoURI);
</script>

<h2 class:margin-bottom-small={$selectedAccountCertified}>
  Postulez pour être certifié !
</h2>

{#if $selectedAccountCertified}
  <Alert type="warning">
    Vous êtes déjà certifié et ne pourrez donc pas repostuler
  </Alert>
{:else}
  <Alert type="secondary">
    Remplissez le formulaire puis confirmez la transaction dans votre wallet !
  </Alert>
{/if}

<div>
  <Popover label={feeInfo} background="secondary">
    <Badge type="warning">
      <i class="fas fa-info-circle"></i> Coût: {applicationPrice} MATIC
    </Badge>
  </Popover>

  <div class="row flex-spaces">
    <Form spellcheck="false">
      <Input placeholder="Entreprise"
             autofocus
             bind:value={companyName}
             class="margin-top margin-bottom" />
      <Input placeholder="Site Web"
             bind:value={webSite}
             type="url"
             class="margin-top margin-bottom" />
      <Input placeholder="Logo (URL)"
             bind:value={logoURI}
             type="url"
             class="margin-top margin-bottom" />

      <Button on:click={submit}
              type="success"
              disabled={!canSubmit}>
        Valider
      </Button>
    </Form>
    <img src="paper_plane_l_blue.png" alt="" />
  </div>
</div>

<style>
.row img {
  align-self: center;
}
</style>