import TxModal from './TxModal.svelte';
const TRANSITION_TIME = 235;
function open(title) {
    const modal = new TxModal({
        target: document.body,
        props: { title },
    });
    // Preserve transition
    setTimeout(modal.$set.bind(modal, { active: true }), 1);
    modal.close = () => {
        modal.$set({ active: false });
        setTimeout(modal.$destroy.bind(modal), TRANSITION_TIME);
    };
    return modal;
}
TxModal.open = open;
export default TxModal;
