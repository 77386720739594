<script lang="ts">
  import { Collapsible } from 'spaper'
  import FAQ from '../FAQ.json'
</script>

<h2>FAQ</h2>

{#each Object.entries(FAQ) as [label, answer] }
  <Collapsible {label}>
    {@html answer}
  </Collapsible>
{/each}
