<script lang="ts">
  import type { Address } from '../helpers/eth-helper'

  const mumbaiExplorer = 'https://mumbai.polygonscan.com'

  const explorers: Record<number, string> = {
    5: 'https://goerli.etherscan.io',
    80001: mumbaiExplorer,
    137: 'https://polygonscan.com'
  }

  const explorer = explorers[DEV_CHAIN] ?? mumbaiExplorer

  export let hash: Address
  export let ressourceType: 'tx' | 'address' = 'tx'
</script>

<a href="{explorer}/{ressourceType}/{hash}"
   target="_blank" rel="noreferrer"
   on:click>
  Voir sur l'explorateur ➚
</a>
