<script lang="ts">
  import { Article, Skeleton } from 'spaper'
  import { gradientColor } from '.'
</script>

<Article border class="border-warning border-thick col-9 col">
  <svelte:fragment slot="meta">
    <Skeleton --mid-gradient={gradientColor} --height="1rem" />
  </svelte:fragment>
  <svelte:fragment slot="textLead">
    <Skeleton --mid-gradient={gradientColor} --height="2rem" />
  </svelte:fragment>
  <Skeleton --mid-gradient={gradientColor} count={2} />
  <svelte:fragment slot="footer">
    <Skeleton --mid-gradient={gradientColor}
              --width="7rem" --height="1rem" />
  </svelte:fragment>
</Article>
