<script lang="ts">
  import { Button, Progress } from 'spaper'
  import partial from 'lodash.partial'
  import { handleTxWithUx } from '../../helpers/tx-helper'
  import { voteBan, type Address } from '../../helpers/eth-helper'
  import {
    selectedAccount,
    selectedAccountCertified
  } from '../../store'

  export let company: Address
  export let votesFor: number
  export let votesAgainst: number
  export let voters: Address[]

  function vote(company: Address, inFavor: Boolean) {
    handleTxWithUx({
      title: `Voter ${inFavor ? 'Pour' : 'Contre'}`,
      tx: voteBan,
      params: [company, inFavor],
      messages: {
        success: 'Vote enregistré !'
      },
    })
  }

  function formatVotePercentage(
    count: number, total: number
  ): number {
    return Math.round(count / total * 100)
  }

  $: canVote = $selectedAccountCertified
    && !voters.includes($selectedAccount)
</script>

<aside class="text-center padding col-3 col">
  <Button type="warning" on:click={partial(vote, company, true)}
          disabled={!canVote}>
    Voter Pour
  </Button>
  <Button type="warning" on:click={partial(vote, company, false)}
          disabled={!canVote}>
    Voter Contre
  </Button>
  <div class="margin-top">
    <span class="text-small">Pour : {votesFor} votes</span>
    <Progress value={formatVotePercentage(votesFor, voters.length)}
              type="warning" showValue striped />
    <span class="text-small">Contre : {votesAgainst} votes</span>
    <Progress value={formatVotePercentage(votesAgainst, voters.length)}
              type="warning" showValue striped />
  </div>
</aside>
