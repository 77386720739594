import PPSData from '../contracts/PunkyPlacesSociety.json';
function getContractData(data, networkId = 5) {
    const { abi } = data;
    return {
        abi,
        address: data.networks[String(networkId)].address
    };
}
const PPS = getContractData(PPSData, DEV_CHAIN);
export { PPS, };
