<script lang="ts">
  import Router from 'svelte-spa-router'
  import { location } from 'svelte-spa-router'
  import routes from './routes'
  import Nav from './components/Nav.svelte'
  import Footer from './components/Footer.svelte'

  $: pageClassName = `${$location.substring(1) || 'home'}-page`
</script>

<Nav />

<main class="paper margin-left-small margin-right-small
             border border-1 border-secondary {pageClassName}">
  <Router {routes} />
</main>

<Footer />

<style lang="scss" global>
@import '../public/global.scss';

main, footer {
  a, a:visited {
    background-image: none;
    color: var(--secondary);
    &:hover {
      color: var(--secondary-light-10);
    }
  }
}

main {
  background-repeat: no-repeat;
  background-position: center 10rem;
  background-image: var(--bg-image, none);
  &.companies-page {
    --bg-image: url(/paper_diploma_l_blue_light.png);
  }
  &.pending-page {
    --bg-image: url(/list_paper_office_l_blue_light.png);
  }
  &.proposals-page {
    --bg-image: url(/building_government_l_blue_light.png);
    background-position: center 6rem;
  }
  &.faq-page {
    --bg-image: url(/thinking_l_blue_light.png);
  }
}

.dark main {
  &.companies-page {
    --bg-image: url(/paper_diploma_l_muted_dark.png);
  }
  &.pending-page {
    --bg-image: url(/list_paper_office_l_muted_dark.png);
  }
  &.proposals-page {
    --bg-image: url(/building_government_l_muted_dark.png);
  }
  &.faq-page {
    --bg-image: url(/thinking_l_muted_dark.png);
  }
}
</style>
