import { Toast } from 'spaper';
import TxModal from '../components/TxModal';
import { provider } from './eth-helper';
import { selectedAccountHasOngoingTx } from '../store';
async function doTransaction(tx, params = []) {
    try {
        const result = await tx.apply(null, params);
        return [result, true];
    }
    catch (error) {
        return [parseErrorMessage(error), false];
    }
}
export async function handleTxWithUx({ title, tx, params = [], messages = {}, onEnd, onConfirm }) {
    messages = Object.assign({
        success: 'Transaction confirmée !',
        error: 'La transaction a échouée :(',
    }, messages);
    const txModal = TxModal.open(title);
    const [data, success] = await doTransaction(tx, params);
    selectedAccountHasOngoingTx.set(true);
    if (!success) {
        selectedAccountHasOngoingTx.set(false);
        txModal.close();
        const { code } = data;
        let errorMessage = 'Une erreur est survenue.';
        if (code === 4001) { // Denied by user
            return;
        }
        if (DEV_CHAIN === 1337) {
            errorMessage += ` ${data.data
                .message.split('VM Exception while processing transaction: revert ')[1]}`;
        }
        else {
            errorMessage += ` ${data.message}`;
        }
        return Toast.error({
            message: errorMessage,
            indefinite: true,
        });
    }
    const hash = data.hash;
    txModal.$set({ txHash: hash });
    provider.once(hash, ({ status }) => {
        const success = status === 1;
        const type = success ? 'success' : 'error';
        Toast[type]({
            message: messages[type],
            indefinite: true,
        });
        selectedAccountHasOngoingTx.set(false);
        txModal.close();
        onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm(success);
    });
    onEnd === null || onEnd === void 0 ? void 0 : onEnd();
}
function parseErrorMessage(error) {
    const { code, message } = error;
    if (typeof code === 'number')
        return error;
    try {
        return JSON.parse(message.substring(message.indexOf('{'), message.indexOf('}}}') + 3));
    }
    catch (_a) {
        return error;
    }
}
