import { providers, Contract, utils, BigNumber, } from 'ethers';
import { PPS } from '../contracts';
let readProvider;
if (DEV_CHAIN === 1337) {
    readProvider = new providers.JsonRpcProvider(`http://localhost:${DEV_HOST !== null && DEV_HOST !== void 0 ? DEV_HOST : 8525}`);
}
else if (DEV_CHAIN === 5) {
    readProvider = new providers.InfuraProvider('goerli');
}
else if (DEV_CHAIN === 80001) {
    readProvider = new providers.InfuraProvider('maticmum');
}
else if (DEV_CHAIN === 137) {
    readProvider = new providers.InfuraProvider('matic');
}
else {
    readProvider = new providers.InfuraProvider('maticmum');
}
const contract = new Contract(PPS.address, PPS.abi, readProvider);
let provider;
let contractWithSigner;
let signer;
(() => {
    if (!window.ethereum)
        return;
    provider = new providers.Web3Provider(window.ethereum, 'any');
    signer = provider.getSigner();
    contractWithSigner = new Contract(PPS.address, PPS.abi, provider).connect(signer);
})();
export async function connectWallet() {
    try {
        const [account] = await provider.send('eth_requestAccounts', []);
        return account;
    }
    catch (error) {
        return null;
    }
}
export async function getConnectedWallet() {
    try {
        return (await signer.getAddress());
    }
    catch (error) {
        return null;
    }
}
export async function getChain() {
    try {
        const { chainId } = await provider.getNetwork();
        return chainId;
    }
    catch (error) {
        return null;
    }
}
export async function switchChain() {
    try {
        await provider.send('wallet_switchEthereumChain', [{ chainId: `0x${DEV_CHAIN}` }]);
    }
    catch (error) {
        return false;
    }
}
export function toWei(amount) {
    // ethers amount to wei in hexadecimal format
    return utils.parseEther(String(amount)).toHexString();
}
export function fromWei(amount) {
    // hexadecimal wei amount to ethers
    return Number(utils.formatEther(amount));
}
// address fetched from contracts are lowercased while those from wallet are not
export function addressMatches(a, b) {
    return (a === null || a === void 0 ? void 0 : a.toLowerCase()) === (b === null || b === void 0 ? void 0 : b.toLowerCase());
}
export async function apply(companyName, webSite, logoURI, price) {
    return contractWithSigner.sendApplication(companyName, webSite, logoURI, { value: toWei(price) });
}
export async function getApplicationPrice() {
    return fromWei(await contract.applicationPrice());
}
export async function approveCompany(companyAddress) {
    return contractWithSigner.approveCompany(companyAddress);
}
export async function editCompany(webSite, logoURI) {
    return contractWithSigner.editCompany(webSite, logoURI);
}
export async function denyCompany(companyAddress) {
    return contractWithSigner.deny(companyAddress);
}
export async function cancelApplication() {
    return contractWithSigner.cancelApplication();
}
export async function getRefund() {
    return contractWithSigner.claimRefund();
}
export async function quit() {
    return contractWithSigner.leave();
}
export async function voteBan(companyAddress, inFavor) {
    return contractWithSigner.voteBan(companyAddress, inFavor);
}
export async function creatBanProposal(companyAddress, reason) {
    return contractWithSigner.proposeBan(companyAddress, reason);
}
export async function hasPendingRefund(companyAddress) {
    return (await contract.pendingRefund(companyAddress)) > 0;
}
export async function isCertified(companyAddress) {
    return contract.isCertified(companyAddress);
}
export async function isLocked(user) {
    return contract.isLocked(user);
}
export async function getCertifiedCompanies() {
    return contract.certifiedCompanies();
}
export async function getPendingApplications() {
    return contract.pendingApplications();
}
export async function getBanProposals() {
    return contract.proposedBans();
}
export { provider, contract, };
