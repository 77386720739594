<script lang="ts">
  import { onMount } from 'svelte'
  import {
    getCertifiedCompanies,
    contract
  } from '../helpers/eth-helper'
  import type { Company } from '../types/company';
  import CompanyList from '../components/companies/CompanyList.svelte'

  let loading: boolean = false
  let companies: Company[] = []
  const events: string[] = [
    'Certified',
    'Exit',
    'Edit',
  ]

  onMount(async () => {
    events.forEach(event => {
      contract.on(event, async () => {
        await getCompanies();
      })
    })

    loading = true;
    await getCompanies();
    loading = false;
  })

  async function getCompanies() {
    companies = await getCertifiedCompanies();
  }
</script>

<h2 class="margin-none">Punky Places</h2>

<CompanyList {companies} {loading} certified />
