import Home from './pages/Home.svelte';
import Application from './pages/Application.svelte';
import Companies from './pages/Companies.svelte';
import Pending from './pages/Pending.svelte';
import FAQ from './pages/FAQ.svelte';
import Proposals from './pages/Proposals.svelte';
export default {
    '/': Home,
    '/apply': Application,
    '/companies': Companies,
    '/pending': Pending,
    '/proposals': Proposals,
    '/faq': FAQ,
};
