import { derived, writable } from 'svelte/store';
import { getConnectedWallet, getChain, provider, isCertified, hasPendingRefund, } from './helpers/eth-helper';
export const selectedAccount = writable(null);
export const chainId = writable(null);
export const hasWallet = writable(true);
export const selectedAccountCertified = writable(false);
export const selectedAccountHasPendingRefund = writable(false);
export const selectedAccountHasOngoingTx = writable(false);
const SUPPORTED_NETWORK = [5, 1337, 80001, 137];
export const connected = derived(selectedAccount, ($selectedAccount) => !!$selectedAccount);
export const onSupportedNetwork = derived(chainId, ($chainId) => SUPPORTED_NETWORK.includes($chainId));
selectedAccount.subscribe(async (val) => {
    if (!val) {
        selectedAccountCertified.set(false);
        selectedAccountHasPendingRefund.set(false);
        return;
    }
    selectedAccountCertified.set(await isCertified(val));
    selectedAccountHasPendingRefund.set(await hasPendingRefund(val));
});
(async () => {
    if (!window.ethereum) {
        hasWallet.set(false);
        return;
    }
    // Can't be done through provider as far as I know
    window.ethereum.on('accountsChanged', ([account]) => {
        selectedAccount.set(account);
        window.location.reload();
    });
    provider.on('network', (_network, previousNetwork) => {
        if (previousNetwork) {
            window.location.reload();
        }
    });
    const connectedAddress = await getConnectedWallet();
    selectedAccount.set(connectedAddress);
    chainId.set(await getChain());
})();
