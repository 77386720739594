<script lang="ts">
  import { onMount } from 'svelte'
  import { Button, Card, Modal, Input, Form, Toast } from 'spaper'
  import { push } from 'svelte-spa-router'
  import partial from 'lodash.partial'
  import { hasWallet, onSupportedNetwork } from '../../store'
  import type { Address } from '../../helpers/eth-helper'
  import { handleTxWithUx } from '../../helpers/tx-helper'
  import { approveCompany,
           editCompany,
           denyCompany,
           cancelApplication,
           addressMatches,
           isLocked } from '../../helpers/eth-helper'
  import { formatDate } from '../../helpers/utils'
  import {
    selectedAccount,
    selectedAccountCertified,
  } from '../../store'
  import CertifiedIcon from '../CertifiedIcon.svelte'

  export let name: string
  export let logoURI: string
  export let webSite: string
  export let date: string
  export let address: Address
  export let certified: boolean = false

  const userLockErrorMessage = 'Votre compte est temporairement bloqué'
  let newWebSite: string = webSite
  let newLogoURI: string = logoURI
  let editModalStatus: boolean = false
  let userIsLocked: boolean = false

  onMount(async () => {
    userIsLocked = await isLocked($selectedAccount)
  })

  async function approveHandler() {
    if (userIsLocked)
      return Toast.warn(userLockErrorMessage)

    await handleTxWithUx({
      title: `Approuver ${name}`,
      tx: approveCompany,
      params: [address],
      onEnd: partial(push, '/companies'),
    })
  }

  async function denyHandler() {
    if (userIsLocked)
      return Toast.warn(userLockErrorMessage)

    await handleTxWithUx({
      title: `Refuser ${name}`,
      tx: denyCompany,
      params: [address],
    })
  }

  async function cancelHandler() {
    await handleTxWithUx({
      title: 'Annuler votre candidature',
      tx: cancelApplication,
      messages: {
        success: 'Candidature annulée !'
      }
    })
  }

  async function doEdit(e: Event) {
    // Can't use modifier on Button
    e.preventDefault();

    editModalStatus = false;
    await handleTxWithUx({
      title: `Éditer ${name}`,
      tx: editCompany,
      params: [newWebSite, newLogoURI],
      messages: {
        success: 'Modifications enregistrées !'
      },
    })
  }

  $: addressMatchesConnected = addressMatches($selectedAccount, address)
</script>

<Card
  title={name}
  image={logoURI}
  class="company-card"
>
  <svelte:fragment slot="header">
    <section>
      {#if certified}
        Certifié <CertifiedIcon />
      {:else}
        Postulant <i class="fas fa-id-badge"></i>
      {/if}
    </section>
    <section>{formatDate(date)}</section>
  </svelte:fragment>
  <a href={webSite} target="_blank" rel="noopener noreferrer">
    {webSite} <i class="fas fa-external-link-alt"></i>
  </a>
  <svelte:fragment slot="footer">
    {#if !certified && $hasWallet && $selectedAccountCertified}
      <Button on:click={approveHandler}
              type="success"
              disabled={!$onSupportedNetwork}>
        Approuver <i class="fas fa-user-check"></i>
      </Button>
    {/if}
    {#if addressMatchesConnected}
      <Button on:click={() => editModalStatus = true}
              type="secondary"
              disabled={!$onSupportedNetwork}
              class="margin-top">
        Éditer <i class="fas fa-edit"></i>
      </Button>
    {/if}
    {#if !certified && addressMatchesConnected}
      <Button on:click={cancelHandler}
              type="danger"
              disabled={!$onSupportedNetwork}
              class="margin-top">
        Annuler <i class="fas fa-ban"></i>
      </Button>
    {/if}
    {#if !certified && $hasWallet && $selectedAccountCertified}
      <Button on:click={denyHandler}
              type="warning"
              disabled={!$onSupportedNetwork}
              class="margin-top">
        Refuser <i class="fas fa-trash"></i>
      </Button>
    {/if}
  </svelte:fragment>
</Card>

<Modal title={`Editer ${name}`}
        bind:active={editModalStatus}
        closeBtnText="Fermer"
        class="border border-5">
  <Form>
    <Input placeholder="Site Web"
           spellcheck="false"
           bind:value={newWebSite}
           class="margin-top margin-bottom" />
    <Input placeholder="Logo (URL)"
           spellcheck="false"
           bind:value={newLogoURI}
           class="margin-top margin-bottom" />
    <Button on:click={doEdit}
            type="success"
            disabled={!$onSupportedNetwork}>
      Valider <i class="fas fa-check"></i>
    </Button>
  </Form>
</Modal>

<style>
:global(.company-card .card-body) {
  text-align: center;
  background-color: var(--main-background);
}
</style>
