<script lang="ts">
  import { Skeleton } from 'spaper'
  import { gradientColor } from '.'
</script>

<aside class="text-center padding col-3 col">
  <Skeleton inline count={2} --mid-gradient={gradientColor}
            --width="6rem" --height="3rem" />
  <Skeleton class="plapla" count={2}
            --mid-gradient={gradientColor} --height="1rem" />
</aside>
