<script lang="ts">
  import type { Address } from '../../helpers/eth-helper'
  import { Modal } from 'spaper'
  import Loader from '../Loader.svelte'
  import ExplorerLink from '../ExplorerLink.svelte'

  export let active: boolean = false
  export let title: string
  export let txHash: Address = null
</script>

<Modal {active} {title}
       closeBtnText="Fermer"
       class="border border-2">
  <Loader />
  {#if txHash}
    <p>Transaction en cours</p>
    <ExplorerLink hash={txHash} on:click={() => active = false} />
  {:else}
    <p>Confirmer la transaction dans votre wallet</p>
  {/if}
</Modal>
